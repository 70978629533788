<template>
  <div class="lesson">
    <div class="lesson__content" v-if="!loading.lesson">
      <template v-if="currentLesson?.is_liberated === true">
        <div class="lesson__topbar">
          <div class="flex-1" />
          <span class="lesson__topbar--rating"> {{ $t("my_courses.avaliar") }} </span>
          <StarRating :show-rating="false" :star-size="20" :rating="currentLesson.my_rating || 0"
            @rating-selected="postRating" />
        </div>

        <div class="lesson__header">
          <div class="lesson__header__info">
            <div class="lesson__header__grid__title">
              <span class="lesson__header__title">{{
                currentLesson.title
              }}</span>
            </div>
            <div :class="{
              lesson__header__controls: true,
              'lesson__header__controls--mobile': getLayout.breakpoint == 1,
            }">
              <ButtonPlay size="sm" class="lesson__header__controls__prev" @click="gotoPrevLesson()"
                :disabled="!prevLesson">
                <IconSax :primary="!!prevLesson" name="arrow-left" />
                {{ $t("my_courses.prev") }}
              </ButtonPlay>
              <ButtonPlay size="sm" class="lesson__header__controls__complete" @click="completeLesson"
                :disabled="currentLesson.is_completed">
                {{ $t("my_courses.marcar") }}
                <IconSax name="tick-circle" />
              </ButtonPlay>
              <ButtonPlay size="sm" class="lesson__header__controls__next" @click="gotoNextLesson()"
                :disabled="!nextLesson">
                {{ $t("my_courses.next") }}
                <IconSax :primary="!!nextLesson" name="arrow-right-1" />
              </ButtonPlay>
            </div>
          </div>

          <div class="lesson__edit" v-if="hasPermission && studentCantView">
            <router-link :to="'/config_curso/' +
              currentLesson.course_id +
              '/module/' +
              currentLesson.module_id +
              '/lesson?lesson_id=' +
              currentLesson.id
              ">
              <span class="lesson__edit--name"> {{ $t("my_courses.editar") }} </span>
              <span class="lesson__edit--icon">
                <IconSax name="edit" primary />
              </span>
            </router-link>
          </div>
        </div>
      </template>

      <template v-if="currentLesson?.is_liberated === true">
        <LessonPlayYoutube v-if="currentLesson.mediaType === 'youtube'" :porcentagem="lessonPercentageCompletion"
          :type="currentLesson.mediaType" class="lesson__player" :source="currentLesson.source"
          :thumb="currentLesson.thumb" />
        <VoompTube v-if="currentLesson.mediaType === 'voomptube'" :type="currentLesson.mediaType"
          :data="currentLesson.source" :next="gotoNextLesson" />
        <LessonPlayVimeo v-if="currentLesson.mediaType === 'vimeo'" :porcentagem="lessonPercentageCompletion"
          :source="currentLesson.source" class="textexx lesson__player" />
        <VideoPlayerIframe v-if="currentLesson.mediaType === 'iframe'" :porcentagem="lessonPercentageCompletion"
          :source="currentLesson.source" class="lesson__player" />
        <LessonPlayPanda v-if="
          currentLesson.mediaType === 'panda' &&
          currentLesson.can_be_displayed
        " class="lesson__player" :porcentagem="lessonPercentageCompletion" :source="currentLesson.source" />
        <LessonPlayAudio v-if="currentLesson && currentLesson.mediaType === 'audio'"
          :porcentagem="lessonPercentageCompletion" :type="currentLesson.mediaType" :source="currentLesson"
          class="lesson__player" />
        <LessonQuiz v-if="currentLesson.mediaType === 'quiz'" :lesson="currentLesson"
          :canComplete="!currentLesson.is_completed" @complete="completeLesson()" />
        <LessonExercise v-if="currentLesson.mediaType === 'exercise'"
          :getSelectedExercise="currentLesson.exercises[0]?.question" :nextButton="false" />
      </template>

      <template v-if="currentLesson?.is_liberated === true">
        <div v-if="showLessonTab" class="lesson__tab">
          <a @click="tabSelected = 'description'" :class="{
            lesson__tab__item: true,
            'lesson__tab__item--selected': tabSelected == 'description',
          }">
            <span class="lesson__tab__item__icon">
              <IconSax size="1rem" name="document-text" primary />
            </span>
            <span class="lesson__tab__item__label"> {{ $t("my_courses.description") }}</span>
          </a>
          <a @click="tabSelected = 'attachements'" :class="{
            lesson__tab__item: true,
            'lesson__tab__item--selected': tabSelected == 'attachements',
          }">
            <span class="lesson__tab__item__icon">
              <IconSax size="1rem" name="folder" primary />
            </span>
            <span class="lesson__tab__item__label">{{ $t("my_courses.documents") }} ({{ attachmentsQuantity }})</span>
          </a>
          <a v-if="settings['coment_lesson']" @click="tabSelected = 'comments'" :class="{
            lesson__tab__item: true,
            'lesson__tab__item--selected': tabSelected == 'comments',
          }">
            <span class="lesson__tab__item__icon">
              <IconSax size="1rem" name="messages-2" primary />
            </span>
            <span class="lesson__tab__item__label">{{ $t("my_courses.comments") }}</span>
          </a>
        </div>
        <div v-if="tabSelected == 'description'" class="lesson__content__description">
          <p v-html="currentLesson.content"></p>
        </div>
        <div v-if="tabSelected == 'attachements'" class="lesson__content__attachements">
          <LessonAttachments :lesson="currentLesson" />
        </div>
        <LessonCommentList v-if="tabSelected == 'comments'" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import CertificateExtensionService from "@/services/resources/CertificateExtensionService";
const certificateExtensionService = CertificateExtensionService.build();

export default {
  watch: {
    currentLesson(lesson) {
      if (lesson.is_liberated === false) {
        setTimeout(() => {
          this.gotoNextLesson();
        }, 2000);
      }
    },
  },
  components: {
    LessonPlayYoutube: () => import("./LessonPlayYoutube.vue"),
    LessonPlayVimeo: () => import("./LessonPlayVimeo.vue"),
    LessonPlayPanda: () => import("./LessonPlayPanda.vue"),
    LessonPlayAudio: () => import("./LessonPlayAudio.vue"),
    VideoPlayerIframe: () => import("@/components/VideoPlayerIframe.vue"),
    LessonQuiz: () => import("./LessonQuiz.vue"),
    LessonAttachments: () => import("./LessonAttachments.vue"),
    LessonCommentList: () => import("./LessonCommentList.vue"),
    LessonExercise: () => import("@/components/exercises/Exercise.vue"),
    StarRating: () => import("vue-star-rating")
  },
  props: ["gotoLesson"],
  mounted() {
    this.actionDefineMainColor();
    this.getOptionsConfig();
  },
  data() {
    return {
      tabSelected: "description",
      autoCompleteLesson: false,
      lessonPercentageCompletion: 0,
      permission: false,
    };
  },
  computed: {
    ...mapGetters({
      settings: "config/getSettings",
      course: "courses/getCourse",
      modules: "courses/getModules",
      currentLesson: "courses/getCurrentLesson",
      coursePercentage: "courses/getCoursePercentage",
      prevLesson: "courses/getPrevLesson",
      nextLesson: "courses/getNextLesson",
      loading: "courses/getLoading",
      comments: "courses/getLessonComments",
      getIsAdmin: "config/getIsAdmin",
      hasPermission: "user/hasPermission",
      studentCantView: "user/studentCantView",
      getIsMobile: "layout/getIsMobile",
      getLayout: "layout/getLayout",
      getCurrentModuleOpened: "courses/getCurrentModuleOpened",
    }),
    showLessonTab() {
      return (
        this.currentLesson.mediaType != "quiz" &&
        this.currentLesson.mediaType != "exercise"
      );
    },
    attachmentsQuantity() {
      return (this.currentLesson && Array.isArray(this.currentLesson.attachments)) ? this.currentLesson.attachments.length : 0;
    },
  },
  methods: {
    ...mapActions({
      actionDefineMainColor: "config/actionDefineMainColor",
      actionCompleteLesson: "courses/actionCompleteLesson",
      actionDisableCertificate: "courses/actionDisableCertificate",
      actionLoadSingleCertificate: "courses/actionLoadSingleCertificate",
      postRating: "courses/actionPostRating",
      actionCurrentLessonOpened: "courses/actionCurrentLessonOpened",
    }),
    async completeLesson() {

      await this.actionCompleteLesson();
      const response = await this.actionLoadSingleCertificate();

      if(response){
        if(response.certificate_extension_config){
          if(certificateExtensionService.shouldShowCertificate(response)){
            this.$root.$emit("bv::show::modal", "modal-certificate-available");
            return false;
          }else{
            this.$root.$emit("openCertifyToModal", response);
            await this.actionDisableCertificate();
          }
        }else{
          this.$root.$emit("openCertifyToModal", response);
          await this.actionDisableCertificate();
        }
      }
      await this.gotoLesson(this.nextLesson);
    },
    async gotoPrevLesson() {
      await this.gotoLesson(this.prevLesson);
    },
    async gotoNextLesson() {
      if (isActive(this.autoCompleteLesson)) {
        await this.completeLesson()
      } else {
        await this.gotoLesson(this.nextLesson);
      }
    },
    quizCompleted(concluida = true, text = this.$t("player.concluida")) {
      this.completedLesson = text;
      this.autoCompleteLesson = concluida;
    },
    getPermission() {
      //busca as permições
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          this.permission = false;
          return;
        }
        this.permission = true;
        // comentar linha a baixo validação para admin n existe
        this.getOptionsConfig();
      } else {
        this.getOptionsConfig();
        this.permission = false;
      }
    },
    getOptionsConfig() {
      this.blockLinks = this.settings["limit_percetage_lesson"] == "on";
      this.lessonPercentageCompletion =
        this.settings["number_limit_percetage_lesson"] || 0;
      this.autoCompleteLesson = this.settings["complete_lessson_next"];
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .vue-star-rating {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}

.lesson {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.25rem;
  height: 100%;
  overflow: auto;

  &__topbar {
    &--rating {
      display: flex;
      align-items: center;
      color: var(--fontcolor);
      margin-right: 10px;
      @extend .body-regular-12;
    }
  }
}

.lesson__edit {
  display: flex;
  width: 100%;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &--icon {
    display: flex;
    align-items: center;
  }

  &--name {
    color: var(--maincolor);
    display: flex;
    align-items: center;
  }

  ::v-deep svg {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;

    path {
      fill: var(--maincolor);
    }
  }
}

.lesson__topbar {
  display: flex;
  flex-direction: row;
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.lesson__content {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  gap: 2.5rem;
}

.lesson__content__description {
  color: var(--fontcolor);
}

.lesson__player {
  height: 100%;
  min-height: 500px;

  ::v-deep iframe {
    width: 100% !important;
  }
}

.progress-space {
  width: 18.75rem;
  height: 0.25rem;
  flex-shrink: 0;
  border-radius: 62.4375rem;
  background: var(--backgroundcolor, #404043);
}

.lesson__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.lesson__header__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.lesson__header__grid__title {
  @media screen and (max-width: 768px) {
    padding-bottom: 2em;
    padding-top: 2em;
  }
}

.lesson__header__title {
  flex: 1;
  color: var(--fontcolor);
  @extend .headline-bold-20;
  margin-bottom: 1em;
  height: 32px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 475px) {
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    min-width: 100%;
  }

  @media screen and (min-width: 1140px) {
    min-width: 15rem;
  }
}

.lesson__header__controls {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  flex: 1;

  @media screen and (min-width: 768px) {
    flex-basis: 475px;
    min-width: 30rem;
  }
}

.lesson__header__controls__prev,
.lesson__header__controls__next {
  .lesson__header__controls--mobile & {
    flex-basis: 45%;
    flex-grow: 1;
  }
}

.lesson__header__controls__complete {
  min-width: 15rem;

  .lesson__header__controls--mobile & {
    order: 3;
    flex: 1;
  }
}

.lesson__tab {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    overflow: auto;
  }
}

.lesson__tab__item {
  all: unset;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  padding: 0.8rem 0rem;
  gap: 10px;

  &__icon {
    display: flex;

    ::v-deep svg {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;

      path {
        fill: var(--maincolor);
      }
    }
  }

  &--selected {
    ::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--maincolor);
      left: 0;
      bottom: 0;
    }
  }
}

.lesson__tab__item__label {
  @extend .body-bold-16;
  color: var(--fontcolor);
}
</style>
